body {
    width: 500px;
    height: 600px;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.wika-app {
    position: relative;
    width: 500px;
    height: 600px;
    border: 1px solid darkgray;
    padding: 0px;
    overflow: hidden;
}






